import './styles.scss';

export const PageNotFound = () => {
    return (
        <div className="blank-page-wrapper">
            <img
                src="/assets/logos/logo.png"
                alt="CodeComrades logo"
                height="96"
                width="95"
                className="mb-10"
            />
            <h1 className="mb-10">Page Not found</h1>
            <p className="fs-3 text-center">
                The page you are trying to reach is either <br /> <strong>under construction</strong> or <strong>not found</strong>!
            </p>
        </div>
    );
};
