import { ReactNode } from 'react';
import { Container } from 'react-bootstrap';

export const Section = ({
    children,
    background,
    title,
    customClass,
}: {
    children: ReactNode;
    background?: string;
    title: string;
    customClass?: string;
}) => {
    return (
        <Container
            className={`section-body text-center  ${customClass} py-md-5 py-4 bg-${background}`}
        >
            <h2 className="mt-0" dangerouslySetInnerHTML={{ __html: title }}></h2>
            {children}
        </Container>
    );
};
