export const debounce = (func: Function, timeout = 300) => {
    let timer: NodeJS.Timeout;
    return (...args: any) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(null, args);
        }, timeout);
    };
};

export const validEmail = (email: string) => {
    const mailFormat = /^([a-z\d.-]+)@([a-z\d-]+)\.([a-z]{2,8})?$/;

    return email.match(mailFormat);
}
