import './styles.scss';
import { Col, Container, Row } from 'react-bootstrap';
import Slider from 'react-slick';
import { SLIDER_SETTINGS, TESTIMONIALS } from '../../config/siteData.config';
import { Quote } from 'react-bootstrap-icons';
import { useEffect, useState } from 'react';
import { Section } from '../../components/section/Section';
import { VideoPlayer } from '../../components/videoPlayer/VideoPlayer';
import { useTranslation } from 'react-i18next';

export const Home = () => {
    const { t, i18n } = useTranslation('home');
    console.log(i18n.language);
    const [loaded, setLoaded] = useState(false);

    const services = [
        {
            heading: t('dedicatedTeams'),
            description: t('dedicatedTeamsDescription'),
        },
        {
            heading: t('consulting'),
            description: t('consultingDescription'),
        },
    ];

    useEffect(() => {
        setLoaded(true);
    }, []);

    return (
        <section className="section section-home pb-0">
            <VideoPlayer text={t('pageHeading')} videoSrc="/assets/home-video-2.mp4" />

            <Section customClass="services" background="white" title={t('services.heading')}>
                <p className="service-head">{t('services.text')}</p>

                <Row className="mt-5 services-row">
                    <Col xs={12} lg={4}>
                        <div className="service">
                            {i18n.language === 'bg' ? (
                                <img src="/assets/chart-bg.png" alt="" />
                            ) : (
                                <img src="/assets/Soft-Dev-chart.png" alt="" />
                            )}
                        </div>
                    </Col>

                    {services.map((service, key) => (
                        <Col xs={12} lg={4} key={key}>
                            <div className="service">
                                <div className={`circle-outer ${loaded ? 'loaded' : ''}`}>
                                    <div className="circle-content">
                                        <h4 className="text-center">{service.heading}</h4>
                                        <p>{service.description}</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Section>

            <Section customClass="section-guarantee" title={t('guarantee.heading')}>
                <Row className="justify-content-md-center">
                    <Col xs={12} lg={9} xl={8} className="text-start mb-5">
                        <div className="guarantee-item">
                            <span>
                                <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 24 24"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M4 6h18V4H4c-1.1 0-2 .9-2 2v11H0v3h14v-3H4V6zm19 2h-6c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V9c0-.55-.45-1-1-1zm-1 9h-4v-7h4v7z"></path>
                                </svg>
                            </span>
                            <div className="guarantee-text">
                                <h5>{t('guarantee.responsive.heading')}</h5>
                                <p>{t('guarantee.responsive.text')}</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} lg={9} xl={8} className="text-start mb-5">
                        <div className="guarantee-item">
                            <span>
                                <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth="0"
                                    version="1.1"
                                    viewBox="0 0 16 16"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M6.5 14.5c0-0.828 0.672-1.5 1.5-1.5s1.5 0.672 1.5 1.5c0 0.828-0.672 1.5-1.5 1.5s-1.5-0.672-1.5-1.5zM0 8c0-0.828 0.672-1.5 1.5-1.5s1.5 0.672 1.5 1.5c0 0.828-0.672 1.5-1.5 1.5s-1.5-0.672-1.5-1.5zM13 8c0-0.828 0.672-1.5 1.5-1.5s1.5 0.672 1.5 1.5c0 0.828-0.672 1.5-1.5 1.5s-1.5-0.672-1.5-1.5zM1.904 3.404c0-0.828 0.672-1.5 1.5-1.5s1.5 0.672 1.5 1.5c0 0.828-0.672 1.5-1.5 1.5s-1.5-0.672-1.5-1.5zM11.096 12.596c0-0.828 0.672-1.5 1.5-1.5s1.5 0.672 1.5 1.5c0 0.828-0.672 1.5-1.5 1.5s-1.5-0.672-1.5-1.5zM1.904 12.596c0-0.828 0.672-1.5 1.5-1.5s1.5 0.672 1.5 1.5c0 0.828-0.672 1.5-1.5 1.5s-1.5-0.672-1.5-1.5zM11.096 3.404c0-0.828 0.672-1.5 1.5-1.5s1.5 0.672 1.5 1.5c0 0.828-0.672 1.5-1.5 1.5s-1.5-0.672-1.5-1.5z"></path>
                                </svg>
                            </span>
                            <div className="guarantee-text">
                                <h5>{t('guarantee.support.heading')}</h5>
                                <p>{t('guarantee.support.text')}</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} lg={9} xl={8} className="text-start">
                        <div className="guarantee-item">
                            <span>
                                <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 1024 1024"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372zm198.4-588.1a32 32 0 0 0-24.5.5L414.9 415 296.4 686c-3.6 8.2-3.6 17.5 0 25.7 3.4 7.8 9.7 13.9 17.7 17 3.8 1.5 7.7 2.2 11.7 2.2 4.4 0 8.7-.9 12.8-2.7l271-118.6 118.5-271a32.06 32.06 0 0 0-17.7-42.7zM576.8 534.4l26.2 26.2-42.4 42.4-26.2-26.2L380 644.4 447.5 490 422 464.4l42.4-42.4 25.5 25.5L644.4 380l-67.6 154.4zM464.4 422L422 464.4l25.5 25.6 86.9 86.8 26.2 26.2 42.4-42.4-26.2-26.2-86.8-86.9z"></path>
                                </svg>
                            </span>
                            <div className="guarantee-text">
                                <h5>{t('guarantee.crossPlatform.heading')}</h5>
                                <p>{t('guarantee.crossPlatform.text')}</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Section>

            <Section background="white" title={t('values.heading')}>
                <div className="company-values">
                    <div className="values-chart">
                        <div className="circle">
                            <div className="value value-1">
                                <div className="value-text">
                                    <h4 className="text-highlight">
                                        {t('values.valuesList.commitment.title')}
                                    </h4>
                                    <p>{t('values.valuesList.commitment.text')} </p>
                                </div>
                                <div className="value-circle">
                                    <img className="main-img" alt="" src="/assets/icons/11.png" />
                                    <img className="alt-img" alt="" src="/assets/icons/11-2.png" />
                                </div>
                            </div>
                            <div className="value value-2">
                                <div className="value-text">
                                    <h4 className="text-highlight">
                                        {t('values.valuesList.synergy.title')}
                                    </h4>
                                    <p>{t('values.valuesList.synergy.text')} </p>
                                </div>
                                <div className="value-circle">
                                    <img
                                        className="main-img"
                                        alt=""
                                        src="/assets/icons/synergy-o.png"
                                    />
                                    <img
                                        className="alt-img"
                                        alt=""
                                        src="/assets/icons/synegy-w.png"
                                    />
                                </div>
                            </div>
                            <div className="value value-3">
                                <div className="value-text">
                                    <h4 className="text-highlight">
                                        {t('values.valuesList.efficiency.title')}
                                    </h4>
                                    <p>{t('values.valuesList.efficiency.text')} </p>
                                </div>
                                <div className="value-circle">
                                    <img className="main-img" alt="" src="/assets/icons/ef-o.png" />
                                    <img className="alt-img" alt="" src="/assets/icons/eff-w.png" />
                                </div>
                            </div>
                            <div className="value value-4">
                                <div className="value-text">
                                    <h4 className="text-highlight">
                                        {t('values.valuesList.reliability.title')}
                                    </h4>
                                    <p>{t('values.valuesList.reliability.text')}</p>
                                </div>
                                <div className="value-circle">
                                    <img className="main-img" alt="" src="/assets/icons/7.png" />
                                    <img className="alt-img" alt="" src="/assets/icons/7-2.png" />
                                </div>
                            </div>
                            <div className="value value-5">
                                <div className="value-text">
                                    <h4 className="text-highlight">
                                        {t('values.valuesList.transparency.title')}
                                    </h4>
                                    <p>{t('values.valuesList.transparency.text')}</p>
                                </div>
                                <div className="value-circle">
                                    <img className="main-img" alt="" src="/assets/icons/10.png" />
                                    <img className="alt-img" alt="" src="/assets/icons/10-2.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Section>

            <Section customClass="overflow-hidden" title={t('testimonials.heading')}>
                <Slider {...SLIDER_SETTINGS}>
                    {TESTIMONIALS.map((testimonial, key) => (
                        <div className="testimonial" key={key}>
                            <div className="testimonial-inner">
                                <img src={`/assets/${testimonial.image}`} alt={testimonial.name} />
                                <div className="testimonial-quote-wrapper">
                                    <Quote key={key} className="testimonial-quote" />
                                    <blockquote>{testimonial.text}</blockquote>
                                    <Quote
                                        key={(key + 1) * 2}
                                        className="testimonial-quote testimonial-quote-end"
                                    />
                                </div>
                                <p>
                                    {testimonial.name} <br />
                                    <span>{testimonial.position}</span>
                                </p>
                            </div>
                        </div>
                    ))}
                </Slider>
            </Section>
        </section>
    );
};
