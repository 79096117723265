import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { ContactUsBanner } from '../../components/contactUsBanner/contactUsBanner';
import { Section } from '../../components/section/Section';
import { SLIDER_EMPLOYEES_SETTINGS } from '../../config/siteData.config';
import './styles.scss';

export const About = () => {
    const [windowSize, setWindowSize] = useState<{
        width?: number;
        height?: number;
    }>();

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const { t } = useTranslation('about');

    function employeeClickHandler(employee: any): void {
        window.open(employee.link, '_blank');
    }

    const employees = [
        {
            name: 'Atanas Zhelev',
            img: 'assets/team/atanas.jpg',
            position: 'Co-founder and CEO',
            link: 'https://www.linkedin.com/in/atanas-zhelev-a964126b/',
        },
        {
            name: 'Mario Mirchovski',
            img: 'assets/team/mario.jpg',
            position: 'Co-founder and CEO',
            link: 'https://www.linkedin.com/in/mario-mirchovski/',
        },
        {
            name: 'Stefan Stankov',
            img: 'assets/team/stefan.jpg',
            position: 'Co-founder and CEO',
            link: 'https://www.linkedin.com/in/stefan-stankov/',
        },
        {
            name: 'Vanesa',
            img: 'assets/team/vanessa.jpg',
            position: 'Marketing Expert / HR',
        },
        {
            name: 'Jassen',
            img: 'assets/team/jassen.jpg',
            position: 'Frontend Developer',
        },
        {
            name: 'Alexandra',
            img: 'assets/team/alexandra.jpg',
            position: 'Frontend Developer',
        },
        {
            name: 'Georgi',
            img: '/assets/ninja.png',
            position: 'Frontend Developer',
        },
        {
            name: 'Hyusein',
            img: '/assets/ninja.png',
            position: 'Frontend Developer',
        },
        {
            name: 'Sergey',
            img: 'assets/team/sergey.jpg',

            position: 'Backend Developer',
        },
        {
            name: 'Martin',
            img: 'assets/team/martin.jpg',

            position: 'Backend Developer',
        },
        {
            name: 'Tedo',
            img: '/assets/ninja.png',
            position: 'Backend Developer',
        },
    ];

    const renderEmployees = () => {
        return employees.map((employee, key) => (
            <Col className="mb-md-4" lg={4} md={6} key={key}>
                <div
                    className={`employee ${employee.link ? 'cursor-pointer' : ''}`}
                    onClick={() => {
                        if (employee.link) {
                            employeeClickHandler(employee);
                        }
                    }}
                >
                    <div className="employee-image">
                        {employee.img ? <img src={employee.img} alt="" /> : ''}
                    </div>

                    <h2>{employee.name}</h2>

                    <p>{employee.position}</p>

                    <div className="circles-wrap">
                        <div className="circles">
                            <span className="circle circle-1"></span>
                            <span className="circle circle-2"></span>
                            <span className="circle circle-3"></span>
                            <span className="circle circle-4"></span>
                        </div>
                    </div>
                </div>
            </Col>
        ));
    };

    return (
        <section className="section section-about">
            <div className="company-image-wrapper w-100"></div>
            <Section customClass="services" background="white" title={t('heading')}>
                <Row className="section-body">
                    <Col>
                        <p className="px-md-5 px-3">{t('aboutText')}</p>
                    </Col>
                </Row>
            </Section>

            <Container className="bg-orange">
                <ContactUsBanner text={t('calloutHeading')} buttonColor={'orange'} />
            </Container>

            <Section title={t('theTeamHeading')}>
                <Row>
                    {windowSize?.width && windowSize?.width <= 768 ? (
                        <Slider {...SLIDER_EMPLOYEES_SETTINGS}>{renderEmployees()}</Slider>
                    ) : (
                        <>{renderEmployees()}</>
                    )}
                </Row>
            </Section>
        </section>
    );
};
