import { Row, Col } from 'react-bootstrap';

export const VideoPlayer = ({ text, videoSrc }: { text: string; videoSrc: string }) => {
    return (
        <Row className="m-0">
            <Col className="p-0">
                <div className="video-wrapper home-video-wrapper">
                    <h1 dangerouslySetInnerHTML={{ __html: text }}></h1>
                    <video autoPlay loop={true} muted={true} playsInline={true}>
                        <source src={videoSrc} type="video/mp4" />
                        Your brow ser does not support the video tag.
                    </video>
                </div>
            </Col>
        </Row>
    );
};
