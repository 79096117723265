import { LanguageDropdown } from '../languageDropdown/LanguageDropdown';
import { Navigation } from '../../components/navigation/Navigation';
import { useNavigate } from 'react-router-dom';
import './styles.scss';
import { Container } from 'react-bootstrap';

export const Header = () => {
    const navigate = useNavigate();

    return (
        <header className="header">
            <Container>
                <div className="header-inner">
                    <div className={`logo-wrapper`}>
                        <div className="logo d-flex" onClick={() => navigate(`/`)}>
                            <img
                                src="/assets/logos/logo-dark.png"
                                alt="CodeComrades logo"
                                height="44"
                                width="44"
                            />
                            <span>CodeComrades</span>
                        </div>
                    </div>

                    <Navigation />
                    <div className="header-actions">
                        <LanguageDropdown />
                    </div>
                </div>
            </Container>
        </header>
    );
};
