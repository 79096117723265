import { EnvelopeOpen, PinMap, Telephone, Linkedin, Envelope } from 'react-bootstrap-icons';

/* ========================== TESTIMONIALS SECTION ==========================*/
export const TESTIMONIALS = [
    {
        name: 'Alexander Baldzhiev',
        position: 'Business Development Manager at Braainy',
        image: 'alexander.jpg',
        text: `I've been working with Mario and his company Code Comrades since 2020. During this period he personally and his teammates managed to establish a transparent and sustainable relationship between our two companies I’d definitely like to recommend their services which helped us to grow and manage better our internal development resources, without losing the focus. Code Comrades' expertise in Front-end technologies ( such as Angular, Ionic, TypeScript) all along with their great understanding of the product, gives me the confides to engage them further whenever we need additional development power in the back-end projects with Java, C# or any other.`,
    },
    {
        name: 'Ivo Yotov',
        position: 'CEO at Develop Soft',
        image: 'ivo_yotov.jpg',
        text: 'They did excellent work on some of our projects, easy to work with, delivered on-time and with high quality.',
    },
    // {
    //     name: 'Martin Nenov',
    //     position: 'Software Architect at RedFowl Ltd',
    //     image: 'ivo_yotov.jpg',
    //     text: 'I was very satisfied with their team — they delivered everything we asked from them',
    // },
];

export const SLIDER_SETTINGS = {
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 1,
            },
        },
        {
            breakpoint: 610,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
};

export const SLIDER_EMPLOYEES_SETTINGS = {
    infinite: true,
    arrows: false,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
};

/* ========================== TECHNOLOGIES ==========================*/
export const TECHNOLOGIES_LOGOS = [
    { name: 'Java', logo: 'java', alt: 'Java' },
    { name: 'Spring', logo: 'spring', alt: 'Springboot' },
    { name: '.Net', logo: 'dotnet', alt: '.net' },
    { name: 'iOS', logo: 'ios', alt: 'iOS' },
    { name: 'Android', logo: 'android', alt: 'Android' },
    { name: 'AWS', logo: 'aws', alt: 'AWS' },
    { name: 'NodeJs', logo: 'nodejs', alt: 'NodeJs' },
    { name: 'React', logo: 'react', alt: 'React' },
    { name: 'React Native', logo: 'react', alt: 'React Native' },
    { name: 'Angular', logo: 'angular', alt: 'Angular' },
    { name: 'JS', logo: 'javascript', alt: 'JavaScript' },
    { name: 'TS', logo: 'typescript', alt: 'TypeScript' },
    { name: 'Stencil', logo: 'stencil', alt: 'StencilJs' },
    { name: 'Ionic', logo: 'ionic', alt: 'Ionic' },
    { name: 'HTML', logo: 'html5', alt: 'HTML5' },
    { name: 'CSS', logo: 'css3', alt: 'CSS3' },
];

/* ========================== CONTACTS SECTION ==========================*/
export const CONTACT_ITEMS = [
    {
        icon: <Telephone />,
        label: 'callUs',
        span: '+359 988 350 468',
    },

    {
        icon: <EnvelopeOpen />,
        label: 'emailUs',
        span: 'contact@codecomrades.com',
    },

    {
        icon: <PinMap />,
        label: 'location',
        span: 'Bulgaria',
    },
];

/* ========================== FOOTER ==========================*/
export const SOCIAL_ICONS = [
    // { href: 'https://bg.linkedin.com/company/codecomrades-bg', icon: <Facebook /> },
    { href: 'https://bg.linkedin.com/company/codecomrades-bg', icon: <Linkedin /> },
    { href: 'mailto:contact@codecomrades.com', icon: <Envelope /> },
];

export const OPEN_POSITIONS = [
    {
        title: 'Senior Java Developer',
        location: 'Remote',
        description: (
            <p>
                <strong>CodeComrades Ltd.</strong> is looking to expand the team with a{' '}
                <strong>Senior Java Developer</strong>. <br />
                <br />
                CodeComrades is a web development company, based in Bulgaria. We are a global
                provider of professional digital production and IT development services. <br />
                <br />
                We are experts in software development, we use modern agile methodologies such as
                squads and tribes, we are not corporates, but Comrades!
            </p>
        ),
        sections: [
            {
                title: 'We offer',
                lines: [
                    'Friendly team of experts',
                    'Flexible working time',
                    'Fully remote – work from wherever you want',
                    'Self-education support',
                    'Paid vacation',
                    'Competitive salary and fair rewards (based upon experience)',
                ],
            },
            {
                title: 'Required skills',
                lines: [
                    '3+ years of experience with Java',
                    'Strong knowledge of SpringBoot',
                    'Strong knowledge of Rest API',
                    'Knowledge in Oracle database',
                    'Knowledge in SQL',
                    'Knowledge of Scrum/TMM',
                    'Good level of English',
                ],
            },
            {
                title: 'Nice to have',
                lines: ['Experience of working with ERP systems'],
            },
        ],
    },
    {
        title: 'Senior Java / Kotlin Software Engineer',
        location: 'Varna / Sofia / Remote',
        description: (
            <p>
                <strong>About the project: </strong>Our partner is a rapidly growing FinTech
                business that is re-defining how individuals engage with their money. They are
                harnessing sophisticated portfolio analytics, innovative behavioral psychology and
                cutting-edge UX/UI to create a revolutionary experience and transformational
                outcomes for users.
            </p>
        ),
        sections: [
            {
                title: 'We offer',
                lines: [
                    'Potential for equity participation',
                    'Excellent salary package ',
                    'Flexible working hours',
                    'Home office',
                    'Dynamic and friendly work environment',
                ],
            },
            {
                title: 'Required skills',
                lines: [
                    '7+ years of experience as a professional software engineer using Kotlin or Java programming language',
                    'Comprehensive and in-depth knowledge and skills on the Spring Framework',
                    'Extensive experience using Service-Oriented, Cloud-based and event-driven techniques',
                    'Extensive experience on using distributed messaging middleware, such Apache Kafka',
                    'Familiarity with Docker containerisation, and Kubernetes based service deployment and management',
                    'Familiarity with Jenkins based automated CI/CD pipelines',
                    'Familiarity with static code analysis, TDD/BDD, exception handling, and monitoring and alerting facilities',
                    'Familiarity with project management tools like Confluence and Jira',
                    'Good level of English',
                ],
            },
            {
                title: 'Nice to have',
                lines: [
                    'Excellent presentation, oral and written communication skills',
                    'Strength in problem solving and issue-resolution with good attention to detail',
                    'Be open-minded, easy-going, and a true team player ',
                    'Ability to develop and mentor junior software engineers',
                ],
            },
        ],
    },
    {
        title: 'QA Engineer',
        location: 'Varna / Sofia / Remote',
        description: (
            <p>
                <strong>About the project: </strong>Our partner is a rapidly growing FinTech
                business that is re-defining how individuals engage with their money. They are
                harnessing sophisticated portfolio analytics, innovative behavioural psychology and
                cutting-edge UX/UI to create a revolutionary experience and transformational.
            </p>
        ),
        sections: [
            {
                title: 'We offer',
                lines: [
                    'Highly competitive salary',
                    'Potential for equity participation',
                    'Interesting projects for international clients',
                    'Standard working hours',
                    'Flexibility for working from home',
                    'Your choice of equipment',
                ],
            },
            {
                title: 'Main responsibilities',
                lines: [
                    'Report to Head of QA, take ownership of OKR items on the QA road map, establish and promote best practices',
                    'Develop and maintain automated tests for ETL pipelines, Data Lake, Data Warehouse, and Data Integration microservices',
                    'Carry out automated and manual tests on various levels of the testing pyramid to ensure robustness and stability of the system',
                    'Collaborate with business stakeholders and product managers to clarify requirements for observability, testability and  consistency',
                    'Support and develop junior QA engineers',
                ],
            },
            {
                title: 'Required skills',
                lines: [
                    '3+ years of experience in writing automated tests in Java',
                    'Strong knowledge in QA processes and test methodologies',
                    'Understanding of SOLID principles and Object-Oriented Programming (OOP)',
                    'Experience with relational databases and SQL queries',
                    'Familiarity with Swagger and Open API standards and writing automated API tests for backend services',
                    'Familiarity with BDD approach using Cucumber, and Contract tests using Pact',
                    'Familiarity with project management tools like Confluence, Jira and Asana',
                    'Ability to scope, plan and execute on multiple projects imultaneously in a fast-paced environment',
                    'Good level of English',
                ],
            },
            {
                title: 'Nice to have',
                lines: [
                    'Communicates rapidly, openly, inclusively and efficiently',
                    'Strength in problem solving and issue-resolution with good attention to detail',
                    'Independent thinker, inquisitive, eager to improve and learn',
                    'Strongly motivated and sets demanding standards for personal excellence',
                    'Autonomous self-starter and highly driven - able to lead small sized groups with limited oversight',
                    'Effective collaborator with other team members ',
                ],
            },
        ],
    },
    {
        title: 'Senior Front-Еnd Software Engineer - React',
        location: 'Varna / Sofia / Remote',
        description: (
            <p>
                <strong>About the project: </strong>Our partner is a rapidly growing FinTech
                business that is re-defining how individuals engage with their money. They are
                harnessing sophisticated portfolio analytics, innovative behavioural psychology and
                cutting-edge UX/UI to create a revolutionary experience and transformational.
            </p>
        ),
        sections: [
            {
                title: 'We offer',
                lines: [
                    'Potential for equity participation',
                    'Excellent salary package ',
                    'Interesting projects for international clients',
                    'Standard working hours',
                    'Dynamic and friendly work environment',
                    'Home office',
                ],
            },
            {
                title: 'Main responsibilities',
                lines: [
                    'Design, build and test new business features on frontend web applications ',
                    'Maintain and continuously improve the quality of existing frontend components by refactoring the architectural design, and applying best practices and state-of-the-art technologies',
                    'Report to the Frontend Chapter Lead, and collaborate with fellow frontend engineers in London and offshore teams',
                    'Either serve as a Squad Lead, or as a member of a Squad; collaborate with fellow Frontend, Backend, Mobile, QA and DevOps engineers on the same Squad',
                    'Document and present solutions by various design diagrams and technical reports',
                    'Support and mentor junior frontend engineers ',
                ],
            },
            {
                title: 'Required skills',
                lines: [
                    'Degree in Comping, Software Engineering, or a similar field ',
                    '5+ years of experience as a professional frontend engineer using Typescript and React framework, including React, Redux, and MobX',
                    'In-depth understanding of OOP and SOLID principles',
                    'Practical experience with micro-frontend architecture and clean architecture ',
                    'Practical experience with unit testing frameworks, such as Jest, ts-mockito and ts-automock',
                    'Experience with Java, Kotlin, Python, or C# would be a bonus',
                    'Familiarity with UI/UX and project management tools',
                    'Good level of English',
                ],
            },
            {
                title: 'Nice to have',
                lines: [
                    'Excellent presentation, oral and written communication skills',
                    'Strength in problem solving and issue-resolution with good attention to detail',
                    'Ability to develop and mentor junior software engineers',
                    'Strongly motivated and sets demanding standards for personal excellence',
                    'Autonomous self-starter and highly driven - able to lead small sized groups with limited oversight',
                    'Be open-minded, easy-going, and a true team player ',
                ],
            },
        ],
    },
];
