import { Navigation } from '../../components/navigation/Navigation';
import './styles.scss';
import { Row, Col, Container } from 'react-bootstrap';
import { SOCIAL_ICONS } from '../../config/siteData.config';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
    const { t } = useTranslation('common');

    return (
        <div className="footer-wrapper">
            <div className="footer-inner">
                <Container>
                    <Row className="justify-content-center footer-navigation-wrapper">
                        <Col md="auto">
                            <Navigation placement="footer" />
                        </Col>
                    </Row>
                    <ul className="socials">
                        {SOCIAL_ICONS.map((item, key) => (
                            <li key={key}>
                                <a target="_blank" rel="noreferrer" href={item.href}>
                                    {item.icon}
                                </a>
                            </li>
                        ))}
                    </ul>
                </Container>
            </div>
            <div className="footer-copyright">
                <Container>
                    <Row className="justify-content-between">
                        <Col md="auto">
                            <p className="mb-0">
                                &copy; {new Date().getFullYear()} <strong>CodeComrades Ltd.</strong>{' '}
                                {t('copyRight')}
                            </p>
                        </Col>
                        <Col md="4">
                            <div
                                className="clutch-widget"
                                data-nofollow="true"
                                data-url="https://widget.clutch.co"
                                data-widget-type="13"
                                data-height="50"
                                data-clutchcompany-id="1954997"
                            ></div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};
