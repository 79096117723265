import { Routes, Route, HashRouter } from 'react-router-dom';
import { Layout } from '../pages/layout/Layout';
import { PageNotFound } from '../pages/page-not-found/PageNotFound';
import { Menu } from '../pages/menu';
import { NavigationItemInterface } from '../components/navigation/types';

export const CommonRoutes = () => {
    const flatten = (menu: NavigationItemInterface[]): NavigationItemInterface[] => {
        return [...menu.flat(), ...menu.flatMap((item) => item.subMenu ?? [])];
    };

    return (
        <HashRouter>
            <Layout>
                <Routes>
                    <Route path="*" element={<PageNotFound />} />
                    {flatten(Menu)
                        .filter((menu) => menu.href)
                        .map(({ href, component }) => {
                            return <Route key={href} path={`/${href}`} element={component} />;
                        })}
                </Routes>
            </Layout>
        </HashRouter>
    );
};
