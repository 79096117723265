import React from 'react';
import './App.scss';
import { CommonRoutes } from './routing/Routes';

function App() {
    return (
        <div className="app">
            <CommonRoutes />
        </div>
    );
}

export default App;
