import { useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import './styles.scss';

export const Loading = ({
    showDice = true,
    fullPage = false,
}: {
    showDice?: boolean;
    fullPage?: boolean;
}) => {
    let randX = 0;
    let randY = 0;
    let interval: NodeJS.Timer;
    useEffect(() => {
        setTimeout(() => {
            if (showDice) {
                rotate();
            }
        }, 100);

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    });

    if (showDice) {
        interval = setInterval(() => {
            rotate();
        }, 2000);
    }

    const rotate = () => {
        const dice = document.querySelector<HTMLElement>('.dice');
        if (dice) {
            randX = (Math.floor(Math.random() * 15) + 1) * 90;
            randY = (Math.floor(Math.random() * 15) + 1) * 90;
            dice.style.transform = 'rotateX(' + randX + 'deg) rotateY(' + randY + 'deg)';
            dice.style.transform = 'rotateX(' + randX + 'deg) rotateY(' + randY + 'deg)';
        }
    };

    const LoadingComponent = () => {
        return (
            <Row align-content="center" className="h-100" style={{ alignItems: 'center' }}>
                <Col className="text-center">
                    {showDice && (
                        <section className="dice-container">
                            <div className="dice-scene">
                                <section className="dice">
                                    <section className="dice__face front">
                                        <div className="dot dot-1"></div>
                                    </section>
                                    <section className="dice__face back">
                                        <div className="dot dot-2"></div>
                                        <div className="dot dot-2"></div>
                                    </section>
                                    <section className="dice__face left">
                                        <div className="dot dot-3"></div>
                                        <div className="dot dot-3"></div>
                                        <div className="dot dot-3"></div>
                                    </section>
                                    <section className="dice__face right">
                                        <div className="dot dot-4"></div>
                                        <div className="dot dot-4"></div>
                                        <div className="dot dot-4"></div>
                                        <div className="dot dot-4"></div>
                                    </section>
                                    <section className="dice__face top">
                                        <div className="dot dot-5-t1"></div>
                                        <div className="dot dot-5-t2"></div>
                                        <div className="dot dot-5-m"></div>
                                        <div className="dot dot-5-b1"></div>
                                        <div className="dot dot-5-b2"></div>
                                    </section>
                                    <section className="dice__face bottom">
                                        <div className="dot dot-6"></div>
                                        <div className="dot dot-6"></div>
                                        <div className="dot dot-6"></div>
                                        <div className="dot dot-6"></div>
                                        <div className="dot dot-6"></div>
                                        <div className="dot dot-6"></div>
                                    </section>
                                </section>
                            </div>
                        </section>
                    )}

                    <p className="loading fs-3">
                        Loading <span>.</span>
                        <span>.</span>
                        <span>.</span>
                    </p>
                </Col>
            </Row>
        );
    };

    return fullPage ? (
        <Container fluid className="vh-100">
            <LoadingComponent />
        </Container>
    ) : (
        <LoadingComponent />
    );
};
