import './styles.scss';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { CONTACT_ITEMS } from '../../config/siteData.config';
import { validEmail } from '../../helpers/utills';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const Contacts = () => {
    const { t } = useTranslation('contacts');
    const defaultInputs = {
        name: '',
        email: '',
        message: '',
        subject: '',
    };
    const [customerInputs, setInputs] = useState(defaultInputs);
    const [errorState, setErrorState] = useState({
        name: '',
        email: '',
        message: '',
    });
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const customerInputsHandler = (e: any) => {
        setInputs((customerInputs: any) => ({
            ...customerInputs,
            [e.target.name]: e.target.value,
        }));
    };

    const validInputs = (
        inputs: any
    ): [
        boolean,
        {
            name: string;
            email: string;
            message: string;
        }
    ] => {
        const error = {
            name: '',
            email: '',
            message: '',
        };
        let isValid = true;
        if (!inputs.name || inputs.name.length === 0) {
            error.name = 'Name cannot be empty.';
            isValid = false;
        }

        if (!inputs.email || !validEmail(inputs.email) || inputs.email.length === 0) {
            error.email = 'Please add valid email.';
            isValid = false;
        }

        if (!inputs.message || inputs.message.length === 0) {
            error.message = 'Massage cannot be empty.';
            isValid = false;
        }

        return [isValid, error];
    };

    const submitHandler = async (e: any) => {
        e.preventDefault();
        const [isValid, validateInputs] = validInputs(customerInputs);
        if (!isValid) {
            setErrorState(validateInputs);
        } else {
            setLoading(true);
            setErrorState({
                name: '',
                email: '',
                message: '',
            });

            let response = await fetch('http://codecomrades.com/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify(customerInputs),
            });
            let result = await response.json();
            if (result && result.status === 'success') {
                setSuccess(true);
                setInputs(defaultInputs);
            }
            setLoading(false);
        }
    };

    return (
        <section className="section section-contacts">
            <div className="service-image-wrapper w-100">
                <h1>
                    {t('heading')}
                    <span className="text-highlight">{t('headingHighlight')}</span>
                </h1>
            </div>

            <Container className="form-contacts-wrapper mt-md-5 h-100">
                <Row className="h-100">
                    <Col sm={12} md={6} className="mb-4 mb-md-0">
                        <ul className="contact-data">
                            {CONTACT_ITEMS.map((item, index) => (
                                <li key={index}>
                                    <h4>
                                        <span className="contact-icon">{item.icon}</span>{' '}
                                        {t(`${item.label}`)}
                                    </h4>
                                    <a
                                        href={`${
                                            index === 0
                                                ? `tel:${item.span}`
                                                : index === 1
                                                ? `mailto:${item.span}`
                                                : ''
                                        }`}
                                    >
                                        {item.span}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </Col>
                    <Col sm={12} md={6}>
                        <div className="section-body h-100">
                            <form className={`h-100 contact-form ${success ? 'success' : ''}`}>
                                <Row
                                    className={
                                        success ? 'h-100 align-items-center text-center' : 'd-none'
                                    }
                                >
                                    <Col>
                                        <div className="contact-form-success">
                                            <p>You have successfully sent your request.</p>
                                            <p>We will contact you soon!</p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row
                                    className={`${success ? 'd-none' : ''} loading ${
                                        loading ? 'loading-active' : ''
                                    }`}
                                >
                                    <Col>
                                        <Row>
                                            <Col className="loading-inner">
                                                <Row
                                                    className={`input-wrapper ${
                                                        errorState.name &&
                                                        errorState.name.length > 0
                                                            ? 'input-wrapper-error'
                                                            : ''
                                                    } pb-4`}
                                                >
                                                    <Col>
                                                        <Form.Label>{t('name')}</Form.Label>
                                                        <Form.Control
                                                            id="nameInputId"
                                                            name="name"
                                                            className="personal-data__input"
                                                            defaultValue={customerInputs?.name}
                                                            onChange={customerInputsHandler}
                                                        />
                                                        <p className="error">
                                                            {t('requiredField')}
                                                        </p>
                                                    </Col>
                                                </Row>
                                                <Row
                                                    className={`input-wrapper ${
                                                        errorState.email &&
                                                        errorState.email.length > 0
                                                            ? 'input-wrapper-error'
                                                            : ''
                                                    } pb-4`}
                                                >
                                                    <Col>
                                                        <Form.Label>{t('email')}</Form.Label>
                                                        <Form.Control
                                                            id="emailInputId"
                                                            name="email"
                                                            defaultValue={customerInputs?.email}
                                                            className="personal-data__input"
                                                            onChange={customerInputsHandler}
                                                        />
                                                        <p className="error">
                                                            {t('requiredField')}
                                                        </p>
                                                    </Col>
                                                </Row>
                                                <Row className="pb-4">
                                                    <Col className={`input-wrapper`}>
                                                        <Form.Label>{t('subject')}</Form.Label>
                                                        <Form.Control
                                                            id="subjectInputId"
                                                            name="subject"
                                                            className="message-input"
                                                            defaultValue={customerInputs?.subject}
                                                            onChange={customerInputsHandler}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row
                                                    className={`input-wrapper ${
                                                        errorState.message &&
                                                        errorState.message.length
                                                            ? 'input-wrapper-error'
                                                            : ''
                                                    } pb-4 mb-4`}
                                                >
                                                    <Col>
                                                        <Form.Label>{t('message')}</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            id="messageInputId"
                                                            name="message"
                                                            defaultValue={customerInputs?.message}
                                                            className="message-input"
                                                            onChange={customerInputsHandler}
                                                        />
                                                        <p className="error">
                                                            {t('requiredField')}
                                                        </p>
                                                    </Col>
                                                </Row>
                                                <div className="loading-overlay">
                                                    <div className="spinner">
                                                        <div className="bubble-1"></div>
                                                        <div className="bubble-2"></div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="justify-content-end">
                                            <Col md={'auto'}>
                                                <Button
                                                    disabled={loading}
                                                    variant="outline-primary"
                                                    onClick={submitHandler}
                                                >
                                                    {t('sendMessage')}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};
