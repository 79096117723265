import React from 'react';
import { ErrorProps, ErrorState } from './types';

export default class ErrorBoundary extends React.Component<ErrorProps, ErrorState> {
    state = { hasError: false, error: null };

    static getDerivedStateFromError(error: Error) {
        return {
            hasError: true,
            error,
        };
    }
    componentDidUpdate(prevProps: ErrorProps) {
        if (prevProps.valueToObserve !== this.props.valueToObserve) {
            this.setState({ hasError: false });
        }
    }

    render(): React.ReactNode {
        return !this.state.hasError ? this.props.children : this.props.fallback;
    }
}
