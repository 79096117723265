import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { NavigationItemInterface } from './types';
import { List } from 'react-bootstrap-icons';
import './styles.scss';
import { useState } from 'react';
import { Menu } from '../../pages/menu';
import { LanguageDropdown } from '../languageDropdown/LanguageDropdown';

export const Navigation = ({ placement = 'header' }: { placement?: 'header' | 'footer' }) => {
    const navigationList = Menu;
    const [toggled, setToggled] = useState<boolean>(false);
    const { t } = useTranslation('navigation');
    const location = useLocation();

    const resetPage = () => {
        setToggled(false);
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 200);
    };

    const renderMenu = (
        { href, subMenu, translation, icon }: NavigationItemInterface,
        index: number
    ) => {
        const linkTo = `${href}`;

        if (subMenu) {
            return (
                <li key={index}>
                    {href ? (
                        <Link to={linkTo}>{t(translation)}</Link>
                    ) : (
                        <span>{t(translation)}</span>
                    )}
                    <ul>
                        {subMenu
                            .filter((menu) => !menu.hidden)
                            .map((subMenuItem: NavigationItemInterface, i: any) =>
                                renderMenu(subMenuItem, i)
                            )}
                    </ul>
                </li>
            );
        } else {
            return (
                <li
                    key={index}
                    className={
                        location.pathname === (linkTo !== '/' ? `/${linkTo}` : linkTo)
                            ? 'active'
                            : ''
                    }
                >
                    {href ? (
                        <Link to={linkTo} onClick={() => resetPage()}>
                            {icon && icon} {t(translation)}
                        </Link>
                    ) : (
                        <span>
                            {icon && icon} {t(translation)}
                        </span>
                    )}
                </li>
            );
        }
    };

    return placement === 'header' ? (
        <div className="navigation-wrapper">
            <nav className={`navigation ${toggled ? 'active' : ''}`}>
                <ul>
                    {navigationList.map((navItem: NavigationItemInterface, index: number) =>
                        renderMenu(navItem, index)
                    )}
                </ul>

                <LanguageDropdown onLangSelect={() => setToggled(false)} />
            </nav>
            <span className="menu-toggler" onClick={() => setToggled(!toggled)}>
                <span className={toggled ? 'active' : ''}></span>
            </span>
        </div>
    ) : (
        <nav className={`navigation navigation-${placement} ${toggled ? 'active' : ''}`}>
            <ul>
                {navigationList.map((navItem: NavigationItemInterface, index: number) =>
                    renderMenu(navItem, index)
                )}
            </ul>
        </nav>
    );
};
