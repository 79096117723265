import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './styles.scss';

export const ContactUsBanner = ({
    buttonColor,
    text,
    icon,
}: {
    buttonColor: string;
    text: string;
    icon?: string;
}) => {
    const { t } = useTranslation('about');
    const navigate = useNavigate();

    const navigateToContacts = () => {
        navigate('/contacts');
    };

    return (
        <div className={`text-white p-3`}>
            <Row className="justify-content-center align-items-center">
                <Col md={7} sm={12}>
                    <Row className="d-flex align-items-center justify-content-center">
                        {icon && (
                            <Col md={3} sm={12} className="text-center text-md-end mb-3 mb-md-0">
                                <img
                                    src={icon}
                                    alt="What we do"
                                    title="What we do"
                                    width={50}
                                    height={50}
                                    style={{ maxWidth: `unset` }}
                                />
                            </Col>
                        )}
                        <Col md={9} sm={12} className="text-center text-md-start">
                            <h4 className="m-0" dangerouslySetInnerHTML={{ __html: text }}></h4>
                        </Col>
                    </Row>
                </Col>
                <Col
                    md={4}
                    sm={12}
                    className="d-flex align-items-center text-capitalize justify-content-center mt-3 mt-md-0"
                >
                    <div className="d-flex align-items-center text-capitalize">
                        <button
                            className={`btn btn-outline-${
                                buttonColor === 'white' ? 'primary' : 'light'
                            } text-white d-flex align-items-center`}
                            onClick={navigateToContacts}
                        >
                            {t('calloutAction')}
                        </button>
                    </div>
                </Col>
            </Row>
        </div>
    );
};
