import './styles.scss';
import { Accordion, Col, Container, Row } from 'react-bootstrap';
import { TECHNOLOGIES_LOGOS } from '../../config/siteData.config';
import { ContactUsBanner } from '../../components/contactUsBanner/contactUsBanner';
import { Section } from '../../components/section/Section';
import { useTranslation } from 'react-i18next';

export const Services = () => {
    const { t, i18n } = useTranslation('services');

    return (
        <section className="section section-services">
            <div className="service-image-wrapper w-100">
                <h1 dangerouslySetInnerHTML={{
                    __html: t('heading')
                }}></h1>
                
            </div>

            <Section customClass="services" background="white" title={t('servicesHeading')}>
                <p className="text-center px-md-5">{t('headingText')}</p>
            </Section>

            <Container className="bg-black">
                <ContactUsBanner
                    text={t('calloutHeadingServices')}
                    buttonColor={'black'}
                    icon="/assets/icons/8.png"
                />
            </Container>

            <Container className="bg-white">
                <Accordion defaultActiveKey="0" className="py-3 services-accordion">
                    <Accordion.Item eventKey="0" className="single-service-row">
                        <Accordion.Header>
                            <div className="single-service service-page">
                                <div className="service-icon">
                                    {' '}
                                    <img
                                        src="assets/icons/SoftSolution.png"
                                        alt="Enterprise Software solutions"
                                        title="Enterprise Software solutions"
                                    />
                                </div>
                                <div className="service-text text-center">
                                    <h4>{t('servicesList.solutions.title')}</h4>
                                </div>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div
                                className="info-content text-justify"
                                dangerouslySetInnerHTML={{
                                    __html: t('servicesList.solutions.text'),
                                }}
                            ></div>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="1" className="single-service-row">
                        <Accordion.Header>
                            <div className="single-service service-page">
                                <div className="service-icon">
                                    {' '}
                                    <img
                                        src="assets/icons/dedicated-teams.png"
                                        alt="Dedicated Teams"
                                        title="Dedicated Teams"
                                    />{' '}
                                </div>
                                <div className="service-text text-center">
                                    <h4>{t('servicesList.teams.title')}</h4>
                                </div>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div
                                className="info-content text-justify"
                                dangerouslySetInnerHTML={{
                                    __html: t('servicesList.teams.text'),
                                }}
                            ></div>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2" className="single-service-row">
                        <Accordion.Header>
                            <div className="single-service service-page">
                                <div className="service-icon">
                                    {' '}
                                    <img
                                        src="assets/icons/consulting.png"
                                        alt="Consulting"
                                        title="Consulting"
                                    />{' '}
                                </div>
                                <div className="service-text text-center">
                                    <h4>{t('servicesList.consulting.title')}</h4>
                                </div>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div
                                className="info-content text-justify"
                                dangerouslySetInnerHTML={{
                                    __html: t('servicesList.consulting.text'),
                                }}
                            ></div>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3" className="single-service-row">
                        <Accordion.Header>
                            <div className="single-service service-page">
                                <div className="service-icon">
                                    {' '}
                                    <img
                                        src="assets/icons/Startup.png"
                                        alt="MVP for startup idea"
                                        title="MVP for startup idea"
                                    />{' '}
                                </div>
                                <div className="service-text text-center">
                                    <h4>{t('servicesList.mvp.title')}</h4>
                                </div>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div
                                className="info-content text-justify"
                                dangerouslySetInnerHTML={{
                                    __html: t('servicesList.mvp.text'),
                                }}
                            ></div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Container>

            <Container>
                <Row>
                    <Col className="text-center mt-md-3 mb-3">
                        <h3 className="text-highlight">{t('benefits.heading')}</h3>
                    </Col>
                </Row>
            </Container>

            <Container className="bg-white p-4">
                <Row>
                    <Col className="align-self-center white">
                        <Row className="advantages-list justify-content-center">
                            <Col lg={4} sm={6} className="advantage-card">
                                <div className="service-icon-list">
                                    {' '}
                                    <img
                                        src="assets/icons/5.png"
                                        alt="Fast approach"
                                        title="Fast approach"
                                    />{' '}
                                </div>
                                <div>
                                    <h4>{t('benefits.list.approach.title')}</h4>
                                    <p>{t('benefits.list.approach.text')}</p>
                                </div>
                            </Col>
                            <Col lg={4} sm={6} className="advantage-card">
                                <div className="service-icon-list">
                                    {' '}
                                    <img
                                        src="assets/icons/15.png"
                                        alt="Flexibility"
                                        title="Flexibility"
                                    />{' '}
                                </div>
                                <div>
                                    <h4>{t('benefits.list.flexibility.title')}</h4>
                                    <p>{t('benefits.list.flexibility.text')}</p>
                                </div>
                            </Col>
                            <Col lg={4} sm={6} className="advantage-card">
                                <div className="service-icon-list">
                                    {' '}
                                    <img
                                        src="assets/icons/16.png"
                                        alt="Scalability"
                                        title="Scalability"
                                    />{' '}
                                </div>
                                <div>
                                    <h4>{t('benefits.list.scalability.title')}</h4>
                                    <p>{t('benefits.list.scalability.text')}</p>
                                </div>
                            </Col>
                            <Col lg={4} sm={6} className="advantage-card">
                                <div className="service-icon-list">
                                    {' '}
                                    <img
                                        src="assets/icons/22.png"
                                        alt="Reduced costs"
                                        title="Reduced costs"
                                    />{' '}
                                </div>
                                <div>
                                    <h4>{t('benefits.list.costs.title')}</h4>
                                    <p>{t('benefits.list.costs.text')}</p>
                                </div>
                            </Col>
                            <Col lg={4} sm={6} className="advantage-card">
                                <div className="service-icon-list">
                                    {' '}
                                    <img
                                        src="assets/icons/17.png"
                                        alt="Quality control"
                                        title="Quality control"
                                    />{' '}
                                </div>
                                <div>
                                    <h4>{t('benefits.list.quality.title')}</h4>
                                    <p>{t('benefits.list.quality.text')}</p>
                                </div>
                            </Col>
                            <Col lg={4} sm={6} className="advantage-card">
                                <div className="service-icon-list">
                                    {' '}
                                    <img
                                        src="assets/icons/20.png"
                                        alt="Collaboration"
                                        title="Collaboration"
                                    />{' '}
                                </div>
                                <div>
                                    <h4>{t('benefits.list.collaboration.title')}</h4>
                                    <p>{t('benefits.list.collaboration.text')}</p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Container>
                <Row className="bg-black text-white p-3 justify-content-center">
                    <Col
                        md={4}
                        sm={12}
                        className="d-flex align-items-center h-100 ml-5 flex-column"
                    >
                        <img
                            src="/assets/icons/12.png"
                            alt="What we do"
                            title="What we do"
                            width={50}
                            height={50}
                            style={{ maxWidth: `unset` }}
                        />
                        <h4 className="m-0 mt-3">{t('calloutLocation')}</h4>
                    </Col>
                    <Col
                        md={8}
                        sm={12}
                        className="d-flex align-items-center justify-content-center mt-3 mt-md-0"
                    >
                        <div className="d-flex align-items-center">
                            <p className="text-white d-flex align-items-center text-center text-left-md">
                                {t('calloutLocationText')}
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container>
                <Row>
                    <Col className="text-center mt-3 mb-3">
                        <h3 className="text-highlight">{t('techStackHeading')}</h3>
                    </Col>
                </Row>
            </Container>

            <Container className="bg-white">
                <Row className="justify-content-center py-3">
                    {TECHNOLOGIES_LOGOS.map((tech, index) => (
                        <Col key={index} lg="auto" className="align-self-center tech-card d-flex">
                            <img
                                alt={tech.alt}
                                src={`/assets/logos/${tech.logo}.svg`}
                                className="tech-card-icon Java EE"
                            />
                            <p className="tech-card-name">{tech.name}</p>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
};
