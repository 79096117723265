import { ReactNode, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import './styles.scss';
import { Header } from '../../components/header/Header';
import { Loading } from '../../components/loading/Loading';
import ErrorBoundary from '../../components/errorBoundary/errorBoundary';
import { ErrorComponent } from '../../components/errorComponent/ErrorComponent';
import { Toast } from '../../components/toast/Toast';
import { Footer } from '../../components/footer/Footer';

export const Layout = ({ children }: { children: ReactNode }) => {
    return (
        <ErrorBoundary fallback={<ErrorComponent />}>
            <Suspense fallback={<Loading fullPage={true} />}>
                <Header />
                <div className="main">
                    <Outlet />
                    {children}
                    <Toast />
                </div>
                <Footer />
            </Suspense>
        </ErrorBoundary>
    );
};
