import { NavigationItemInterface } from '../../components/navigation/types';
import { Home } from '../home/Home';
import { About } from '../about/About';
import { Services } from '../services/Services';
import { Careers } from '../careers/Careers';
import { Contacts } from '../contacts/Contacts';

export const Menu: NavigationItemInterface[] = [
    {
        translation: 'home',
        href: '/',
        component: <Home />,
    },
    {
        translation: 'about',
        href: 'about',
        component: <About />,
    },
    {
        translation: 'services',
        href: 'services',
        component: <Services />,
    },

    {
        translation: 'careers',
        href: 'careers',
        component: <Careers />,
    },
    {
        translation: 'contacts',
        href: 'contacts',
        component: <Contacts />,
    },
];
