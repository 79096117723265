import { useTranslation } from 'react-i18next';
import './styles.scss';

export const availableLanguages = [
    { label: 'EN', value: 'en' },
    { label: 'BG', value: 'bg' },
];

export const LanguageDropdown = ({ onLangSelect }: { onLangSelect?: () => void }) => {
    const { i18n } = useTranslation();
    const onSelectLabel = (lang: string | null) => {
        if (lang) {
            i18n.changeLanguage(lang);
            if (onLangSelect) {
                onLangSelect();
            }
        }
    };

    return (
        <div className="language-selector">
            {availableLanguages.map((lang, key) => {
                return [
                    <span
                        key={key}
                        className={i18n.language === lang.value ? 'active' : ''}
                        onClick={() => onSelectLabel(lang.value)}
                        title={lang.label}
                    >
                        {lang.label}
                    </span>,
                    <b key={key + 1}>{key === 0 && '|'}</b>,
                ];
            })}
        </div>
    );
};
