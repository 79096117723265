import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import {
    navigationEN,
    homePageEN,
    commonEN,
    contactsPageEN,
    aboutPageEN,
    servicesPageEN,
} from './locales/en';
import {
    navigationBG,
    homePageBG,
    commonBG,
    contactsPageBG,
    aboutPageBG,
    servicesPageBG,
} from './locales/bg';

const resources = {
    en: {
        navigation: navigationEN,
        home: homePageEN,
        common: commonEN,
        contacts: contactsPageEN,
        about: aboutPageEN,
        services: servicesPageEN,
    },
    bg: {
        navigation: navigationBG,
        home: homePageBG,
        common: commonBG,
        contacts: contactsPageBG,
        about: aboutPageBG,
        services: servicesPageBG,
    },
};
i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en',
        debug: true,
        ns: ['common'],
        defaultNS: 'common',
        interpolation: {
            escapeValue: false,
        },
        parseMissingKeyHandler: (key: string) => {
            return `<${key}>`;
        },
    });

export default i18n;
