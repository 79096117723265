import './styles.scss';
import { Accordion, Card, Col, Container, Row, useAccordionButton } from 'react-bootstrap';
import { OPEN_POSITIONS } from '../../config/siteData.config';
import { VideoPlayer } from '../../components/videoPlayer/VideoPlayer';
import { useTranslation } from 'react-i18next';

function CustomToggle({ children, eventKey }: any) {
    const accordionHeaderClick = useAccordionButton(eventKey, () => {});

    return (
        <div className="careers-toggle-button" onClick={accordionHeaderClick}>
            {children}
        </div>
    );
}

const OpenPositions = () => {
    const { t } = useTranslation('common');

    return (
        <Accordion defaultActiveKey="0" className="mt-5">
            {OPEN_POSITIONS.map((item, key) => (
                <Card key={key} className="position-item border-0 mb-3">
                    <Card.Header className="border-0">
                        <CustomToggle eventKey={key.toString()}>
                            <div className="title">{item.title}</div>
                            <div className="location">{item.location}</div>
                        </CustomToggle>
                    </Card.Header>

                    <Accordion.Collapse eventKey={key.toString()}>
                        <Card.Body>
                            <Row className="description mt-3">
                                <Col>{item.description}</Col>
                            </Row>

                            {item.sections.map((section, sectionKey) => (
                                <Row key={sectionKey} className="position-list mb-3">
                                    <Col>
                                        <p>
                                            <strong>{section.title}</strong>
                                        </p>

                                        {section.lines.map((line, lineKey) => (
                                            <p key={lineKey} className="line">
                                                <span className="text-highlight me-2">✓</span>
                                                {line}
                                            </p>
                                        ))}
                                    </Col>
                                </Row>
                            ))}

                            <Row className="mt-4">
                                <Col>
                                    <p>
                                        <strong>
                                            Do not hesitate to apply! We will review your CV and if
                                            we think we could cooperate, we will get in touch.
                                        </strong>
                                    </p>

                                    <p>
                                        <strong>
                                            Please send your CV in English to{' '}
                                            <a
                                                href="mailto:contact@codecomrades.com"
                                                className="text-highlight"
                                            >
                                                contact@codecomrades.com
                                            </a>
                                        </strong>
                                    </p>

                                    <p>
                                        <i>
                                            Your application will be treated with confidentiality.
                                        </i>
                                    </p>

                                    <p className="text-center">
                                        <a
                                            href="mailto:contact@codecomrades.com"
                                            className="btn btn-outline-primary"
                                        >
                                            {t('applyNow')}
                                        </a>
                                    </p>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            ))}
        </Accordion>
    );
};

export const Careers = () => {
    const { t } = useTranslation('common');

    return (
        <section className="section section-careers">
            <VideoPlayer text={t('careersHeading')} videoSrc="/assets/careerpage.mp4" />

            <Container>
                <Row>
                    <Col>
                        <OpenPositions />
                    </Col>
                </Row>
            </Container>
        </section>
    );
};
